import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import ChangePassword from '../Components/Settings/ChangePassword';



class SettingsContainer extends Component {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));
        let username = user.user.email;

        this.state = {
            username: username
        }
    }

    
    
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                    <ChangePassword history={this.props.history} username={this.state.username} />

                </div>
            </div>
        )
    }
}

export default withAuth(SettingsContainer);