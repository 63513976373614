import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class SubjectTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    showData(value){
        if(Array.isArray(value) && value.length > 0){
            let new_arr = value.map((el,index) => el.label);
            
            return new_arr.join(",");
        }else{
            return '';
        }
    }



    render() {
        const columns = [
            {
                name: "Class Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Dept Code",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Term",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Intake",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Compulsory",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Major",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Optional",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if(table_data.length > 0){
            data = table_data.map((el,index)=>
                [el.class_name, el.course_name, el.stream_name, el.dept_code, el.course_type, el.term, el.intake, this.showData(el.compulsory), this.showData(el.major), this.showData(el.optional), <Launch onClick={this.onActionClick.bind(this,el)} />]
            )
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Class List"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
