import React, { Component } from 'react'
import './css/bullet.css'

export default class NavigationBullets extends Component {
    render() {
        let step = this.props.step;
        let  i = ['', '','',''];
        for(var j = step; j >= 0; j--){
            i[j] = 'active';
        }
        return (
            <ul className="progressbar">
                <li className={i[0]}>Basic Details</li>
                <li className={i[1]}>Academic Details</li>
                <li className={i[2]}>Done</li>
            </ul>
        )
    }
}
