import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {GetData} from './../../../api/service';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    typef: {
        fontSize: 16,
        fontWeight: 400
    },
    redalert: {
        color: 'red',
        fontSize: 10
    }
});


class PrimaryInformations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program: '',
            branch_code: '',
            current_semester: '',
            roll_no: '',
            hasError: [false,false,false,false],
            branches: [],
            f_branches: [],
            semesters: [],
            errors: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    _initialisation(){
        GetData('/AEC/getcollegebranches')
        .then((resp) => {
            if(resp.length > 0){
                this.setState({
                    branches: resp
                })
            }
        })
        
    }

    componentDidMount(){
        this._initialisation();
        let data = this.props.data;
        

            this.setState({
                program: data.program,
                branch_code: data.branch_code,
                current_semester: data.current_semester,
                roll_no: data.roll_no,
                branches: data.branches,
                f_branches: data.f_branches,
                semesters: data.semesters
            })


        
    }


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.name === 'program'){
            let program = e.target.value;
            let branches = this.state.branches;
            let results = branches.filter(el => el.program === program);
            this.setState({
                f_branches: results
            })
        }

        if(e.target.name === 'branch_code'){
            let branch_code = e.target.value;
            GetData(`/AEC/${branch_code}/getsemestersingle`)
            .then((resp) => {
                if(resp.length > 0){
                    this.setState({
                        semesters: resp
                    })
                }
            })
        }
    }

    hanldeValidation(){
        let hasError = this.state.hasError;
        let isValid = true;
        let errors = {};

        let {program,branch_code,current_semester,roll_no} = this.state;

        if(program === ''){
            errors['program'] = 'This is required!';
            isValid = false;
        }else{
            errors['program'] = '';
        }

        if (branch_code === '') {
            errors['branch_code'] = 'This is required!';
            isValid = false;
        } else {
            errors['branch_code'] = '';
        }

        if (current_semester === '') {
            errors['current_semester'] = 'This is required!';
            isValid = false;
        } else {
            errors['current_semester'] = '';
        }

        if (roll_no === '') {
            errors['roll_no'] = 'This is required!';
            isValid = false;
        } else {
            errors['roll_no'] = '';
        }

        if (typeof roll_no !== "undefined") {
            if (!roll_no.match(/^\d{2}([-])\d{3}$/)) {
                errors['roll_no'] = 'Roll number must be in format e.g. 13-373';
                isValid = false;
            }else{
                errors['roll_no'] = '';
            }
        }

        this.setState({
            errors
        })

        return isValid;


    }

    onSubmit(e) {
        e.preventDefault();

        if(this.hanldeValidation()){
            let data = this.state;

            this.props.onSetData(data);


            ///send the data and step the form a step forward
        }

    }
    render() {
        const { classes } = this.props;
        const {hasError} = this.state;

        let i = [];
        let branches = this.state.f_branches;
        if(Array.isArray(branches) && branches.length > 0){
            i = branches.map((el,index) => 
                <MenuItem key={index} value={el.branch_code}>{el.branch_code} - {el.branch_name}</MenuItem>
            )
        }

        let j = [];
        let semesters = this.state.semesters;
        if (Array.isArray(semesters) && semesters.length > 0) {
            j = semesters.map((el, index) =>
                <MenuItem key={index} value={el.id}>{el.semester_name}</MenuItem>
            )
        }
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" className={classes.typef}>Program</InputLabel>
                        <Select
                            name="program"
                            value={this.state.program}
                            onChange={this.handleChange}
                            className={classes.typef}
                            
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="UG">UG</MenuItem>
                            <MenuItem value="PG">PG</MenuItem>
                            <MenuItem value="PhD">PhD</MenuItem>
                        </Select>
                        {this.state.errors['program'] && <FormHelperText className={classes.redalert}>{this.state.errors['program']}</FormHelperText>}
                    </FormControl>


                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" className={classes.typef}>Branch Name</InputLabel>
                        <Select
                            name="branch_code"
                            value={this.state.branch_code}
                            onChange={this.handleChange}
                            className={classes.typef}
                          
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                           {i}
                        </Select>
                        {this.state.errors['branch_code'] && <FormHelperText className={classes.redalert}>{this.state.errors['branch_code']}</FormHelperText>}
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" className={classes.typef}>Semester</InputLabel>
                        <Select
                            name="current_semester"
                            value={this.state.current_semester}
                            onChange={this.handleChange}
                            className={classes.typef}
                       
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {j}
                        </Select>
                        {this.state.errors['current_semester'] && <FormHelperText className={classes.redalert}>{this.state.errors['current_semester']}</FormHelperText>}
                    </FormControl>


                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" className={classes.typef}>Roll No (e.g. 13-373)</InputLabel>
                        <Input
                            name="roll_no"
                            value={this.state.roll_no}
                            onChange={this.handleChange}
                            className={classes.typef}
                        />
                        {this.state.errors['roll_no'] && <FormHelperText className={classes.redalert}>{this.state.errors['roll_no']}</FormHelperText>}
                    </FormControl>


                    <div>
                        <Button
                            disabled={true}
                            onClick={this.handleBack}
                            className={classes.button}
                        >
                            Back
                      </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                        >
                            Next
                        </Button>
                    </div>


                </form>


            </div>
        )
    }
}

export default withStyles(styles)(PrimaryInformations);