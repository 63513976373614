import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';

class HomePage extends Component {
  render() {
    return (
      <div>
        <Navbar history={this.props.history} title="DashBoard" breadcumb="HOME / DashBoard"/>
        <div className="main-body">

          <Welcome history={this.props.history} />  
        
        </div>
      </div>
    )
  }
}

export default withAuth(HomePage);