import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import Initialisation from '../Components/Initialisation/Initialisation';

class InitialisationContainer extends Component {
    render() {
        return (
            <div className="initialisation-container">
                

                    <Initialisation history={this.props.history} />

               
            </div>
        )
    }
}

export default withAuth(InitialisationContainer);