import React, { Component } from 'react'
import NavigationBullets from './NavigationBullets';
import './css/table-grid.css'

export default class StepThree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }



    }

    

   
    render() {
        
        return (
            <div className="container">
                <br />
                <div className="container">
                    <NavigationBullets step="2" />
                </div>

                <br />
                <br />
                <br />
                <br />

                <p className="alert alert-warning" align="center" style={{fontWeight: 'bold'}}><i className="fa fa-warning"></i> You are about to submit the basic details of your college. Please reverify before submitting.</p>
                <div className="container" align="center">
                    <button className="btn btn-success" type="button" onClick={this.props.onFinalSubmit}>Final Submit</button>&nbsp;
                    <button className="btn btn-danger" type="button" onClick={this.props.onPrevClick}>Go Back</button>
                </div>

                      
            </div>
        )
    }
}


export const BranchCard = (props) => {
    return (
        <div className="display-grid-div">
            <div className="display-grid-div-left">
                <h4>{props.branch_name}</h4>
                <h6>{props.estd}, Intake: {props.intake}</h6>
            </div>

            <div className="display-grid-div-right">

                <h6><i onClick={props.onClick} className="fa fa-trash"></i></h6>
            </div>
        </div>
    )
}
