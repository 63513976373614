import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


class CollegeCommittee extends Component {
    state = {
        id: '',
        edit: false,
        name: '',
        description: '',
        committee_code: '',
        members: [],
        member_name: '',
        member_designation: '',
        data: [],
        isDataLoaded: false,
        dept_code: this.props.dept_code,
        message: '',
        open: false,
        loadFile: true
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    __getDeptData = () => {
        GetData(`/getcommittee`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isDataLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__getDeptData();

    }


    onChangeMember = (name, value, index) => {
        let members1 = this.state.members;
    
        members1[index][name] = value;
    
        this.setState({
          members: members1,
        });
      };

    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            members: this.state.members,
            committee_code: this.state.committee_code
        }

        if (this.state.edit) {
            PutData(`/admin/${this.state.id}/editcommittee`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        } else {
            PostData(`/admin/addcommittee`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        }


    }

    onDeleteClick = (el) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, el.id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deletecommittee`)
            .then((resp) => {
                this.setState({
                    message: resp,
                    open: true
                }, () => this.__getDeptData())
            })
    }

    onEditClick = (el) => {
        this.setState({
            ...el,
            edit: true,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }

    cancelEdit = () => {
        this.setState({
            id: '',
            name: '',
            description: '',
            committee_code: '',
            members: [],
            member_name: '',
            member_designation: '',
            edit: false,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }

    addMember = () => {
        let members = this.state.members;

        if (this.state.memeber_name != '' && this.state.member_designation != '') {
            let d = {
                member_name: this.state.member_name,
                member_designation: this.state.member_designation
            }
            members.push(d)
            this.setState({
                members: members,
                member_name: '',
                member_designation: ''
            })
        }
    }

    onDelete = (index) => {
        let members = this.state.members;

        members.splice(index,1);
        this.setState({
            members: members
        })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        College Committee
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        gutterBottom
                    >
                        Delete committees and add new committee
                    </Typography>


                    <Card>

                        <Card.Body>


                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Committee Code</label>
                                    <FormControl
                                        as="input"
                                        name="committee_code"
                                        
                                        onChange={this.onChange}
                                        value={this.state.committee_code}
                                        placeholder="Committee Code"
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Name</label>
                                    <FormControl
                                        as="input"
                                        name="name"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        placeholder="Name"
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <label>Description (Functions/Objectives)</label>
                                        <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.description}
                                        onChange={this.handleChange.bind(this, 'description')}
                                    />
                                   
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Member Name</label>
                                    <FormControl
                                        as="input"
                                        name="member_name"
                                        onChange={this.onChange}
                                        value={this.state.member_name}
                                        placeholder="Member Name"
                                    />
                                    <label>Designation</label>
                                    <FormControl
                                        as="input"
                                        name="member_designation"
                                        onChange={this.onChange}
                                        value={this.state.member_designation}
                                        placeholder="Designation"
                                    />
                                    <br />

                                    <Button
                                        size="sm"
                                        onClick={this.addMember}
                                    >
                                        Add Member
                                    </Button>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>List of Members</label>
                                    <br />
                                    {this.state.members.map((el, index) => (
                    <div key={index}>
                      <Grid container>
                        <Grid item xs={5} lg={5} md={5} sm={5}>
                          <input
                            className="form-control form-control-sm"
                            value={el.member_name}
                            onChange={(e) =>
                              this.onChangeMember(
                                "member_name",
                                e.target.value,
                                index
                              )
                            }
                            placeholder="Member Name"
                          />
                        </Grid>
                        <Grid item xs={5} lg={5} md={5} sm={5}>
                          <input
                            className="form-control form-control-sm"
                            value={el.member_designation}
                            onChange={(e) =>
                              this.onChangeMember(
                                "member_designation",
                                e.target.value,
                                index
                              )
                            }
                            placeholder="Designation"
                          />
                        </Grid>
                        <Grid item xs={2} lg={2} md={2} sm={2}>
                          <Delete onClick={this.onDelete.bind(this, index)} />
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                                </Grid>







                            </Grid>



                            {this.state.edit ? (
                                <div align="right">
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="button"
                                        onClick={this.cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (<div align="right">
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="submit"
                                >
                                    Final Submit
                    </Button>
                            </div>)}

                        </Card.Body>

                    </Card>

                    <br />


                    <Card>

                        <Card.Body>
                            {this.state.isDataLoaded ? (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell width="30%">Name</TableCell>
                                                <TableCell width="30%">Description</TableCell>
                                                <TableCell wodth='20%'>Members</TableCell>

                                               
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data.map((el, index) =>

                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{el.name}</TableCell>
                                                    <TableCell>
                                                        <div dangerouslySetInnerHTML={{__html: el.description}}></div>
                                                    </TableCell>
                                                   
                                                    <TableCell>
                                                        {Array.isArray(el.members) && el.members.map((el1, index1) =>
                                                            <Chip
                                                                key={index1}
                                                                label={`${el1.member_name} : ${el1.member_designation}`}
                                                            />
                                                        )}
                                                    </TableCell>
                                                   
                                                    <TableCell><Edit onClick={this.onEditClick.bind(this, el)} /></TableCell>
                                                    <TableCell><Delete onClick={this.onDeleteClick.bind(this, el)} /></TableCell>
                                                </TableRow>

                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            ) : <Typography>
                                    Loading . . .
                            </Typography>}
                        </Card.Body>

                    </Card>

                </form>
            </div>
        );
    }
}


export default CollegeCommittee;