import React, { Component } from 'react'
import NavigationBullets from './NavigationBullets';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    typef: {
        fontSize: 16,
        fontWeight: 400
    },
    redalert: {
        color: 'red',
        fontSize: 10
    }
});

class StepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            name: '',
            father_name: '',
            mother_name: '',
            date_of_admission: '',
            date_of_birth: '',
            gender: 'MALE',
            caste: 'GENERAL',
            phone: '',
            present_address: '',
            permanent_address: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount(){
        let data = this.props.data;
        this.setState({
            name: data.name,
            father_name: data.father_name,
            mother_name: data.mother_name,
            date_of_admission: data.date_of_admission,
            date_of_birth: data.date_of_birth,
            gender: data.gender,
            caste: data.caste,
            phone: data.phone,
            present_address: data.present_address,
            permanent_address: data.permanent_address,
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        this.props.onSetData(data);

    }





    render() {
        const { classes } = this.props;
        return (
            <div>

                <form onSubmit={this.onSubmit}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef}>Your Full Name</InputLabel>
                        <Input
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        {this.state.errors['name'] && <FormHelperText className={classes.redalert}>{this.state.errors['name']}</FormHelperText>}
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef}>Father's Name</InputLabel>
                        <Input
                            name="father_name"
                            value={this.state.father_name}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        {this.state.errors['father_name'] && <FormHelperText className={classes.redalert}>{this.state.errors['father_name']}</FormHelperText>}
                    </FormControl>


                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef}>Mother's Name</InputLabel>
                        <Input
                            name="mother_name"
                            value={this.state.mother_name}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        {this.state.errors['mother_name'] && <FormHelperText className={classes.redalert}>{this.state.errors['mother_name']}</FormHelperText>}
                    </FormControl>


                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef} >Date of Admission</InputLabel>
                        <Input
                            type="date"
                            name="date_of_admission"
                            value={this.state.date_of_admission}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        {this.state.errors['date_of_admission'] && <FormHelperText className={classes.redalert}>{this.state.errors['date_of_admission']}</FormHelperText>}
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef} >Date of Birth</InputLabel>
                        <Input
                            type="date"
                            name="date_of_birth"
                            value={this.state.date_of_birth}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        {this.state.errors['date_of_birth'] && <FormHelperText className={classes.redalert}>{this.state.errors['date_of_birth']}</FormHelperText>}
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" className={classes.typef}>Gender</InputLabel>
                        <Select
                            name="gender"
                            value={this.state.gender}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required

                        >
                            <MenuItem value="MALE">MALE</MenuItem>
                            <MenuItem value="FEMALE">FEMALE</MenuItem>
                            <MenuItem value="OTHER">OTHER</MenuItem>
                        </Select>
                        {this.state.errors['gender'] && <FormHelperText className={classes.redalert}>{this.state.errors['gender']}</FormHelperText>}
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" className={classes.typef}>Caste</InputLabel>
                        <Select
                            name="caste"
                            value={this.state.caste}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        >
                            <MenuItem value="GENERAL">GENERAL</MenuItem>
                            <MenuItem value="OBC">OBC</MenuItem>
                            <MenuItem value="ST(H)">ST(H)</MenuItem>
                            <MenuItem value="ST(P)">ST(P)</MenuItem>
                            <MenuItem value="SC">SC</MenuItem>
                            <MenuItem value="MOBC">MOBC</MenuItem>
                        </Select>
                        {this.state.errors['gender'] && <FormHelperText className={classes.redalert}>{this.state.errors['gender']}</FormHelperText>}
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef}>Phone Number</InputLabel>
                        <Input
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        {this.state.errors['phone'] && <FormHelperText className={classes.redalert}>{this.state.errors['phone']}</FormHelperText>}
                    </FormControl>

                    <br/>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef}>Present Address</InputLabel>
                        <Input
                            id="standard-multiline-flexible"
                            multiline
                            rows="2"
                            name="present_address"
                            value={this.state.present_address}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />
                        
                    </FormControl>

       
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="age-helper" shrink className={classes.typef}>Permanent Address</InputLabel>
                        <Input
                            id="standard-multiline-flexible"
                            multiline
                            rows="2"
                            name="permanent_address"
                            value={this.state.permanent_address}
                            onChange={this.handleChange}
                            className={classes.typef}
                            required
                        />

                    </FormControl>


                    


                    <div>
                        <Button
                            onClick={this.props.onBackClick}
                            className={classes.button}
                        >
                            Back
                      </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}


export default withStyles(styles)(StepOne);