import { Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import DepartmentUsers from './VisitorsNote/DepartmentUsers'


export default function VisitorsLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      

      

      <div style={{ paddingTop: 10 }}>
        
       <DepartmentUsers apikey={apikey} />
      </div>
    </div>
  )
}
