import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class LeaveTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    getText(value) {
        if (value === 1) {
            return "YES";
        } else {
            return "NO";
        }
    }



    render() {
        const columns = [
            {
                name: "Leave Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Leave Description",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Days",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Is renewable",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if(table_data.length > 0){
            data = table_data.map((el,index)=>
                [el.leave_type, el.leave_desc, el.no_of_days, this.getText(el.is_renewal), <Launch onClick={this.onActionClick.bind(this,el)} />]
            )
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Leave List"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
