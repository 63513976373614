import React, { Component } from 'react'
import { withStyles, Card, CardContent } from '@material-ui/core';
import { GetData } from './../../api/service';

const styles = theme => ({
    logBack: {
        backgroundColor: 'black'
    },
    lineColor: {
        color: 'lightgreen'
    }
})

class ViewLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: []
        }

    }

    componentDidMount() {
        GetData(`/${this.props.apikey}/${this.props.role}/${this.props.username}/log`)
            .then((resp) => {
                this.setState({
                    isLoading: false,
                    data: resp
                })
            })
    }


    render() {
        let { classes } = this.props;
        let i = [];
        if (!this.state.isLoading) {
            let data = this.state.data;
            i = data.map((el, index) =>
                <li className={classes.lineColor} key={index}>{el}</li>
            )
        } else {
            i = <li key="0">Loading.....Please wait</li>
        }

        return (
            <div>
                <Card className={classes.logBack}>
                    <CardContent>
                        <ul>
                            {i}
                        </ul>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(ViewLog)