import React, { Component } from 'react'
import StepOne from './Forms/StepOne';
import StepTwo from './Forms/StepTwo';
import { GetData, PostData } from '../../api/service';
import StepThree from './Forms/StepThree';
import Notifications, { notify } from 'react-notify-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PrimaryInformations from './NewForms/PrimaryInformations';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



const styles = theme => ({
    root: {
        width: '80vw'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
    typef: {
        fontSize: 16
    }
});


function getSteps() {
    return ['Primary Information', 'Basic information'];
}


class Initialisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            step_1: [],
            step_2: {
                gender: 'MALE',
                caste: 'GENERAL'
            },
            open: false
        }

        this.getStepContent = this.getStepContent.bind(this);
        this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
    }

    onSetData(data) {
        this.setState({
            step_1: data
        })
        this.handleNext();
    }

    onSetData2(data) {
        this.setState({
            step_2: data
        })
        this.handleNext();
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return <PrimaryInformations data={this.state.step_1} onSetData={this.onSetData.bind(this)} />;
            case 1:
                return <StepOne data={this.state.step_2} onSetData={this.onSetData2.bind(this)} onBackClick={this.handleBack} />;
            default:
                return 'Unknown step';
        }
    }

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    handleFinalSubmit() {
        let user = JSON.parse(localStorage.getItem('user'));
        let username = user.user.username;
        let { program, branch_code, current_semester, roll_no } = this.state.step_1
        let data = {
            ...this.state.step_2,
            program: program,
            branch_code: branch_code,
            current_semester: current_semester,
            college_roll_no: roll_no,
            username: username,
            university_roll_no: '',
            registration_no: '',
            quota: '',
            state: '',
            is_placed: 0,
            placement: [],
            details: [],
            semester_data: [],
            achievements: [],
            college_code: 'AEC'
        }

        PostData('/addstudent', data)
            .then((resp) => {
                this.setState({
                    open: true
                })
            })
    }

    goToDashBoard(){
        this.props.history.replace('/');
    }

    render() {

        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;


        return (
            <div style={{ padding: '30px' }}>
                <Notifications />
                <Card>

                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                        <StepContent>
                                            {this.getStepContent(index)}
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography style={{ fontSize: 14 }}>All steps completed - Click the Finish Button to continue</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleFinalSubmit}
                                    className={classes.button}>
                                    Final Submit
            </Button>
                            </Paper>
                        )}
                    </div>

                </Card>


                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title">{"You have been succesfully registered"}</DialogTitle>
                    <DialogActions>

                        <Button onClick={this.goToDashBoard.bind(this)} color="primary" autoFocus>
                            OKAY
            </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }
}


export default withStyles(styles)(Initialisation);