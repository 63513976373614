import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';



export default class GroupTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    showData(value){
        if(Array.isArray(value) && value.length > 0){
            let new_arr = value.map((el,index) => el.label);
            
            return new_arr.join(",");
        }else{
            return '';
        }
    }



    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Phone",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Email",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Message",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Time",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Delete",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            // customToolbar: () => {
            //     return (
            //         <IconButton onClick={this.props.onAddClick}><Add  /></IconButton>
            //     )
            // }
        };

        let data = [];
        let table_data = this.props.table_data;
        if(table_data.length > 0){
            data = table_data.map((el,index)=>
                [
                    el.id,
                    el.name,
                    el.phone,
                    el.email,
                    el.message,
                    el.time_stamp,
                    <Delete onClick={this.onActionClick.bind(this,el.id)} />
                ]
            )
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={`Visitors Note`}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
