import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton,Switch, FormGroup, FormControlLabel } from '@material-ui/core';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    }
})

class DepartmentAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dept_code: '',
            dept_name: '',
            is_teaching: 0,
            chec: false,
            id: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeS = this.onChangeS.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeS(e) {
        let is_teaching = this.state.is_teaching;
        if(e.target.checked){
            is_teaching = 1
        }else{
            is_teaching = 0
        }
        
        this.setState({
            chec: e.target.checked,
            is_teaching
        })
        
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                dept_code: '',
                dept_name: '',
                is_teaching: 0,
                check: false,
                id: ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edit !== this.props.edit) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data,
                    chec: this.props.edit_data.is_teaching
                })
            } else {
                this.setState({
                    dept_code: '',
                    dept_name: '',
                    is_teaching: 0,
                    id: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
                dept_code: '',
                dept_name: '',
                is_teaching: 0,
                chec: false,
                id: ''
            })
        }

    }

    onDeleteClick(id){
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>
                            <TextField
                                label="Department Code"
                                name="dept_code"
                                className={classes.textField}
                                type="text"
                                autoComplete="current-password"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.dept_code}
                                onChange={this.onChange}
                            />

                            <TextField
                                label="Department Name"
                                className={classes.textField}
                                type="text"
                                name="dept_name"
                                autoComplete="current-password"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.dept_name}
                                onChange={this.onChange}
                            />


                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="is_active"
                                            checked={this.state.chec}
                                            onChange={this.onChangeS}
                
                                        />
                                    }
                                    label="is Teaching Department ?"
                                />
                                </FormGroup>

                            <br />
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this,this.props.edit_data.dept_code)}
                                            >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(DepartmentAdd)