import React, { Component } from 'react';
import withAuth from '../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import VisitorsLanding from '../Components/VisitorsContainer/VisitorsLanding';

class VisitorsContainer extends Component {
    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user')).user;
        this.state = {
            apikey: user.apikey,
            username: user.email,
            role: user.role
        }
    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                     <VisitorsLanding
                        apikey={this.state.apikey}
                        username={this.state.username}
                        role={this.state.role}
                        history={this.props.history} /> 

                </div>
            </div>
        )
    }
}

export default withAuth(VisitorsContainer);