import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button } from 'react-bootstrap';
import { PostData } from '../../api/service';
import Snack from '../Snackbar/Snack';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class DepartmentResearch extends Component {
    state = {
        online_classes: '',
        dept_code: this.props.dept_code,
        message: '',
        open: false,
        isLoaded: false
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    componentDidMount() {
        
            this.setState({
                online_classes: this.props.online_classes,
                isLoaded: true
            })
        
    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            value: this.state.online_classes,
            type: 'online_classes',
            dept_code: this.props.dept_code
        }

        PostData(`/admin/${this.props.dept_code}/uploaddata`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    message: resp,
                    open: true
                })
            })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Researches
                    </Typography>

                    {/* <ReactQuill
                        placeholder="Type something here"
                        value={this.state.online_classes}
                        onChange={this.handleChange.bind(this, 'online_classes')}
                    /> */}


{this.state.isLoaded && <CKEditor
                    editor={ ClassicEditor }
                    data={this.state.online_classes}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.handleChange(`online_classes`, data)
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />}

                    <br />

                    <div align="right">
                        <Button
                            size="sm"
                            variant="info"
                            type="submit"
                        >
                            Submit
                    </Button>
                    </div>

                </form>
            </div>
        );
    }
}


export default DepartmentResearch;