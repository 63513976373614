import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";

class DepartmentQuestionBank extends Component {
  state = {
    id: "",
    edit: false,
    type: "",
    sub_type: "",
    date: "",
    index1: "",
    academic_program: "",
    paper_name: "",
    paper_code: "",
    semester: "",
    resource_generated_by: "",
    topic: "",
    name_of_external: "",
    link: "",
    year: "",
    upload_info: [],
    data: [],
    isDataLoaded: false,
    dept_code: this.props.dept_code,
    message: "",
    open: false,
    loadFile: true,
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  __getDeptData = () => {
    console.log(this.props);
    GetData(`/${this.props.dept_code}/getquestionbanks`).then((resp) => {
      //console.log(resp)
      this.setState({
        data: resp,
        isDataLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__getDeptData();
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = {
      id: this.state.id,
      type: this.props.type,
      sub_type: this.state.sub_type,
      dept_code: this.props.dept_code,
      date: this.state.date,
      academic_program: this.state.academic_program,
      paper_name: this.state.paper_name,
      paper_code: this.state.paper_code,
      semester: this.state.semester,
      resource_generated_by: this.state.resource_generated_by,
      topic: this.state.topic,
      name_of_external: this.state.name_of_external,
      link: this.state.link,
      year: this.state.year,
      upload_info: this.state.upload_info,
      index1: this.state.index1,
    };

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editquestionbank`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    } else {
      PostData(`/admin/addquestionbank`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletequestionbank`).then(
      (resp) => {
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
      }
    );
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  cancelEdit = () => {
    this.setState(
      {
        id: "",
        edit: false,
        type: "",
        sub_type: "",
        date: "",
        academic_program: "",
        paper_name: "",
        paper_code: "",
        semester: "",
        resource_generated_by: "",
        topic: "",
        name_of_external: "",
        link: "",
        year: "",
        index1: "",
        upload_info: [],
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.type}
          </Typography>

          <Card>
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Type</label>
                  <FormControl
                    as="select"
                    name="sub_type"
                    required
                    onChange={this.onChange}
                    value={this.state.sub_type}
                  >
                    <option value="">Select Type</option>

                    {this.props.type == "QUESTION_BANK" && [
                      <option value="PREVIOUS YEAR QUESTION PAPER">
                        PREVIOUS YEAR QUESTION PAPER
                      </option>,
                      <option value="DEPARTMENT-GENERATED QUESTION BANK">
                        DEPARTMENT-GENERATED QUESTION BANK
                      </option>,
                      <option value="QUESTION BANK FROM OTHER SOURCES">
                        QUESTION BANK FROM OTHER SOURCES
                      </option>,
                    ]}

                    {this.props.type == "VIDEO_BANK" && [
                      <option value="SESSION BY EXTERNAL EXPERT">
                        SESSION BY EXTERNAL EXPERT
                      </option>,
                      <option value="SESSION BY DEPARTMENTAL FACULTY">
                        SESSION BY DEPARTMENTAL FACULTY
                      </option>,
                      <option value="GENERIC VIDEOS">GENERIC VIDEOS</option>,
                      <option value="OTHERS">OTHERS</option>,
                    ]}

                    {this.props.type == "PPT_BANK" && [
                      <option value="PPT BY EXTERNAL EXPERT">
                        PPT BY EXTERNAL EXPERT
                      </option>,
                      <option value="PPT BY DEPARTMENTAL FACULTY">
                        PPT BY DEPARTMENTAL FACULTY
                      </option>,
                      <option value="GENERIC PPT">GENERIC PPT</option>,
                      <option value="OTHERS">OTHERS</option>,
                    ]}
                  </FormControl>
                </Grid>

               

                {this.props.type == "QUESTION_BANK" && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>ACADEMIC PROGRAMME</label>
                      <FormControl
                        as="input"
                        name="academic_program"
                        required
                        onChange={this.onChange}
                        value={this.state.academic_program}
                        placeholder="ACADEMIC PROGRAMME"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>PAPER NAME</label>
                      <FormControl
                        as="input"
                        name="paper_name"
                        required
                        onChange={this.onChange}
                        value={this.state.paper_name}
                        placeholder="PAPER NAME"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>PAPER CODE</label>
                      <FormControl
                        as="input"
                        name="paper_code"
                        required
                        onChange={this.onChange}
                        value={this.state.paper_code}
                        placeholder="PAPER CODE"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>SEMESTER/YEAR</label>
                      <FormControl
                        as="input"
                        name="semester"
                        required
                        onChange={this.onChange}
                        value={this.state.semester}
                        placeholder="SEMESTER"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>RESOURCE GENERATED BY</label>
                      <FormControl
                        as="input"
                        name="resource_generated_by"
                        required
                        onChange={this.onChange}
                        value={this.state.resource_generated_by}
                        placeholder="RESOURCE GENERATED BY"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>YEAR OF QUESTION BANK GENERATION</label>
                      <FormControl
                        as="input"
                        name="year"
                        required
                        onChange={this.onChange}
                        value={this.state.year}
                        placeholder="YEAR OF QUESTION BANK GENERATION"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <label>Upload Question Bank</label>
                      {this.state.loadFile ? (
                        <FileUpload
                          dp={this.state.upload_info}
                          type={`QUESTION_BANK`}
                          setDp={this.handleChange.bind(this, "upload_info")}
                        />
                      ) : null}
                    </Grid>
                  </>
                )}

                {this.props.type == "VIDEO_BANK" && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>TARGET ACADEMIC PROGRAMME</label>
                      <FormControl
                        as="input"
                        name="academic_program"
                        required
                        onChange={this.onChange}
                        value={this.state.academic_program}
                        placeholder="TARGET ACADEMIC PROGRAMME"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>ASSOCIATED PAPER NAME</label>
                      <FormControl
                        as="input"
                        name="paper_name"
                        required
                        onChange={this.onChange}
                        value={this.state.paper_name}
                        placeholder="ASSOCIATED PAPER NAME"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>ASSOCIATED PAPER CODE</label>
                      <FormControl
                        as="input"
                        name="paper_code"
                        required
                        onChange={this.onChange}
                        value={this.state.paper_code}
                        placeholder="ASSOCIATED PAPER CODE"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>TARGET SEMESTER/ YEAR</label>
                      <FormControl
                        as="input"
                        name="semester"
                        required
                        onChange={this.onChange}
                        value={this.state.semester}
                        placeholder="TARGET SEMESTER/ YEAR"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>TOPIC OF THE VIDEO</label>
                      <FormControl
                        as="input"
                        name="topic"
                        required
                        onChange={this.onChange}
                        value={this.state.topic}
                        placeholder="TOPIC OF THE VIDEO"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>NAME OF EXTERNAL/ INTERNAL EXPERT</label>
                      <FormControl
                        as="input"
                        name="name_of_external"
                        required
                        onChange={this.onChange}
                        value={this.state.name_of_external}
                        placeholder="NAME OF EXTERNAL/ INTERNAL EXPERT"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>RESOURCE GENERATED BY</label>
                      <FormControl
                        as="input"
                        name="resource_generated_by"
                        required
                        onChange={this.onChange}
                        value={this.state.resource_generated_by}
                        placeholder="RESOURCE GENERATED BY"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>LINK</label>
                      <FormControl
                        as="input"
                        name="link"
                        required
                        onChange={this.onChange}
                        value={this.state.link}
                        placeholder="LINK"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <label>Upload Thumbnail</label>
                      {this.state.loadFile ? (
                        <FileUpload
                          dp={this.state.upload_info}
                          type={`VIDEO_BANK`}
                          setDp={this.handleChange.bind(this, "upload_info")}
                        />
                      ) : null}
                    </Grid>
                  </>
                )}

                {this.props.type == "PPT_BANK" && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>TARGET ACADEMIC PROGRAMME</label>
                      <FormControl
                        as="input"
                        name="academic_program"
                        required
                        onChange={this.onChange}
                        value={this.state.academic_program}
                        placeholder="TARGET ACADEMIC PROGRAMME"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>ASSOCIATED PAPER NAME</label>
                      <FormControl
                        as="input"
                        name="paper_name"
                        required
                        onChange={this.onChange}
                        value={this.state.paper_name}
                        placeholder="ASSOCIATED PAPER NAME"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>ASSOCIATED PAPER CODE</label>
                      <FormControl
                        as="input"
                        name="paper_code"
                        required
                        onChange={this.onChange}
                        value={this.state.paper_code}
                        placeholder="ASSOCIATED PAPER CODE"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>TARGET SEMESTER/ YEAR</label>
                      <FormControl
                        as="input"
                        name="semester"
                        required
                        onChange={this.onChange}
                        value={this.state.semester}
                        placeholder="TARGET SEMESTER/ YEAR"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>TOPIC OF THE PPT</label>
                      <FormControl
                        as="input"
                        name="topic"
                        required
                        onChange={this.onChange}
                        value={this.state.topic}
                        placeholder="TOPIC OF THE PPT"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>NAME OF EXTERNAL/ INTERNAL EXPERT</label>
                      <FormControl
                        as="input"
                        name="name_of_external"
                        required
                        onChange={this.onChange}
                        value={this.state.name_of_external}
                        placeholder="NAME OF EXTERNAL/ INTERNAL EXPERT"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <label>RESOURCE GENERATED BY</label>
                      <FormControl
                        as="input"
                        name="resource_generated_by"
                        required
                        onChange={this.onChange}
                        value={this.state.resource_generated_by}
                        placeholder="RESOURCE GENERATED BY"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <label>Upload PPT</label>
                      {this.state.loadFile ? (
                        <FileUpload
                          dp={this.state.upload_info}
                          type={`PPT_BANK`}
                          setDp={this.handleChange.bind(this, "upload_info")}
                        />
                      ) : null}
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Index (Order)</label>
                  <FormControl
                    as="input"
                    name="index1"
                    onChange={this.onChange}
                    value={this.state.index1}
                    placeholder="Order"
                  />
                </Grid>
              </Grid>

              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={this.cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>

          <br />

          <Card>
            <Card.Body>
              {this.state.isDataLoaded ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>

                        {this.props.type == "QUESTION_BANK" && (
                          <>
                            <TableCell width="10%">
                              TYPE OF QUESTION BANK
                            </TableCell>
                            <TableCell wodth="10%">
                              ACADEMIC PROGRAMME
                            </TableCell>
                            <TableCell wodth="10%">PAPER NAME</TableCell>
                            <TableCell wodth="10%">PAPER CODE</TableCell>
                            <TableCell wodth="10%">SEMESTER/ YEAR</TableCell>
                            <TableCell wodth="10%">
                              RESOURCE GENERATED BY
                            </TableCell>
                            <TableCell wodth="10%">
                              YEAR OF QUESTION BANK GENERATION
                            </TableCell>
                            <TableCell wodth="10%">
                              UPLOAD QUESTION BANK
                            </TableCell>
                          </>
                        )}

                        {this.props.type == "VIDEO_BANK" && (
                          <>
                            {" "}
                            <TableCell width="10%">
                              TYPE OF VIDEO RESOURCE
                            </TableCell>
                            <TableCell wodth="10%">
                              TARGET ACADEMIC PROGRAMME
                            </TableCell>
                            <TableCell wodth="10%">
                              ASSOCIATED PAPER NAME
                            </TableCell>
                            <TableCell wodth="10%">
                              ASSOCIATED PAPER CODE
                            </TableCell>
                            <TableCell wodth="10%">
                              TARGET SEMESTER/ YEAR
                            </TableCell>
                            <TableCell wodth="10%">
                              TOPIC OF THE VIDEO
                            </TableCell>
                            <TableCell wodth="10%">
                              NAME OF EXTERNAL/ INTERNAL EXPERT
                            </TableCell>
                            <TableCell wodth="10%">
                              RESOURCE GENERATED BY
                            </TableCell>
                            <TableCell wodth="10%">LINK</TableCell>
                            <TableCell wodth="10%">THUMBNAIL</TableCell>
                          </>
                        )}

                        {this.props.type == "PPT_BANK" && (
                          <>
                            {" "}
                            <TableCell width="10%">
                              TYPE OF PPT RESOURCE
                            </TableCell>
                            <TableCell wodth="10%">
                              TARGET ACADEMIC PROGRAMME
                            </TableCell>
                            <TableCell wodth="10%">
                              ASSOCIATED PAPER NAME
                            </TableCell>
                            <TableCell wodth="10%">
                              ASSOCIATED PAPER CODE
                            </TableCell>
                            <TableCell wodth="10%">
                              TARGET SEMESTER/ YEAR
                            </TableCell>
                            <TableCell wodth="10%">TOPIC OF THE PPT</TableCell>
                            <TableCell wodth="10%">
                              NAME OF EXTERNAL/ INTERNAL EXPERT
                            </TableCell>
                            <TableCell wodth="10%">
                              RESOURCE GENERATED BY
                            </TableCell>
                            <TableCell wodth="10%">PPT FILE</TableCell>
                          </>
                        )}

                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.data.filter(el => el.type == this.props.type).map((el, index) => (
                        <TableRow key={index}>
                          <TableCell>{el.index1}</TableCell>

                          {this.props.type == "QUESTION_BANK" && (
                            <>
                              <TableCell width="10%">{el.sub_type}</TableCell>
                              <TableCell wodth="10%">
                                {el.academic_program}
                              </TableCell>
                              <TableCell wodth="10%">
                                {el.paper_name}
                              </TableCell>
                              <TableCell wodth="10%">{el.paper_code}</TableCell>
                              <TableCell wodth="10%">{el.semester}</TableCell>
                              <TableCell wodth="10%">
                                {el.resource_generated_by}
                              </TableCell>
                              <TableCell wodth="10%">{el.year}</TableCell>
                              <TableCell wodth="10%">
                                {Array.isArray(el.upload_info) &&
                                  el.upload_info.map((el1, index1) => (
                                    <Chip key={index1} label={el1.path} />
                                  ))}
                              </TableCell>
                            </>
                          )}

                          {this.props.type == "VIDEO_BANK" && (
                            <>
                              {" "}
                              <TableCell width="10%">{el.sub_type}</TableCell>
                              <TableCell wodth="10%">
                                {el.academic_program}
                              </TableCell>
                              <TableCell wodth="10%">{el.paper_name}</TableCell>
                              <TableCell wodth="10%">{el.paper_code}</TableCell>
                              <TableCell wodth="10%">{el.semester}</TableCell>
                              <TableCell wodth="10%">{el.topic}</TableCell>
                              <TableCell wodth="10%">
                                {el.name_of_external}
                              </TableCell>
                              <TableCell wodth="10%">
                                {el.resource_generated_by}
                              </TableCell>
                              <TableCell wodth="10%"><a href={el.link} target="_blank">Link</a></TableCell>
                              <TableCell wodth="10%">
                                {Array.isArray(el.upload_info) &&
                                  el.upload_info.map((el1, index1) => (
                                    <Chip key={index1} label={el1.path} />
                                  ))}
                              </TableCell>
                            </>
                          )}

                          {this.props.type == "PPT_BANK" && (
                            <>
                              {" "}
                              <TableCell width="10%">{el.sub_type}</TableCell>
                              <TableCell wodth="10%">
                                {el.academic_program}
                              </TableCell>
                              <TableCell wodth="10%">{el.paper_name}</TableCell>
                              <TableCell wodth="10%">{el.paper_code}</TableCell>
                              <TableCell wodth="10%">{el.semester}</TableCell>
                              <TableCell wodth="10%">{el.topic}</TableCell>
                              <TableCell wodth="10%">
                                {el.name_of_external}
                              </TableCell>
                              <TableCell wodth="10%">
                                {el.resource_generated_by}
                              </TableCell>
                      
                              <TableCell wodth="10%">
                                {Array.isArray(el.upload_info) &&
                                  el.upload_info.map((el1, index1) => (
                                    <Chip key={index1} label={el1.path} />
                                  ))}
                              </TableCell>
                            </>
                          )}

                          <TableCell>
                            <Edit onClick={this.onEditClick.bind(this, el)} />
                          </TableCell>
                          <TableCell>
                            <Delete
                              onClick={this.onDeleteClick.bind(this, el)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Typography>Loading . . .</Typography>
              )}
            </Card.Body>
          </Card>
        </form>
      </div>
    );
  }
}

export default DepartmentQuestionBank;
