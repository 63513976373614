import React, { Component } from 'react'
import NavigationBullets from './NavigationBullets';
import './css/table-grid.css'

export default class StepTwo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            added: [],
            branch_code: '',
            estd: '',
            intake: ''
        }

        this.addItem = this.addItem.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    componentDidMount(){
        let d = JSON.parse(localStorage.getItem('added'));
        if(d !== null){
            this.setState({
                added: d
            })
        }
    }

    onChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidation(){
        let branch_code = this.state.branch_code;
        let estd = this.state.estd;
        let intake = this.state.intake;

        let isValid = true;

        if(branch_code === ''){
            isValid = false
        }

        if(estd === ''){
            isValid = false;
        }

        if(intake === ''){
            isValid = false;

        }

        return isValid;


    }

    addItem() {
        let data = {
            branch_code: this.state.branch_code,
            estd: this.state.estd,
            intake: this.state.intake
        }

        if(this.handleValidation()){
            let added = this.state.added;
            added.push(data);

            this.setState({
                added: added,
                branch_code: '',
                estd: '',
                intake: ''
            })
        }
        

    }

    deletePress(index){
        let added = this.state.added;
        added.splice(index,1);
        this.setState({
            added: added
        })
    }


    onNext(){
        let added = this.state.added;
        if(added.length > 0){
            localStorage.setItem('added', JSON.stringify(added));
            this.props.onNextClick();
        }
    }

    render() {
        let i = [];
        if (!this.props.isLoading) {
            let branches = this.props.branches;
            if (branches.length > 0) {
                i = branches.map((el, index) =>
                    <option key={index} value={el.branch_code}>{el.branch_code} - {el.branch_name} ({el.program})</option>
                )
            }
        }

        let j = [];
        let added = this.state.added;
        if(added.length > 0){
            j = added.map((el,index) =>
                <BranchCard 
                    key={index}
                    branch_name={el.branch_code}
                    estd={el.estd}
                    intake={el.intake}
                    onClick={this.deletePress.bind(this,index)}
                />
            )
        }
        return (
            <div className="container">
            <br/>
                <div className="container">
                    <NavigationBullets step="1" />
                </div>

                <br />

                <h3>Academic Details</h3>
                <table width="100%" className="table-form">
                    <tbody>

                        <tr>
                            <td>
                                <label>Branch Name</label>
                                <select
                                    className="form-control input-sm"
                                    name="branch_code"
                                    value={this.state.branch_code}
                                    onChange={this.onChange}

                                >
                                    <option value="">Select Branch</option>
                                    {i}
                                </select>
                            </td>
                            <td>
                                <label>Estd. in</label>
                                <input
                                    className="form-control input-sm"
                                    name="estd"
                                    value={this.state.estd}
                                    onChange={this.onChange}

                                />
                            </td>
                            <td>
                                <label>Current Intake Capacity</label>
                                <input
                                    className="form-control input-sm"
                                    name="intake"
                                    value={this.state.intake}
                                    onChange={this.onChange}

                                />
                            </td>
                            <td>    <br />
                                <button className="btn btn-sm btn-primary" type="button" onClick={this.addItem}>Add</button>
                            </td>
                        </tr>


                        <tr>
                            <td colSpan="4">
                                <div className="table-display-grid">
                                    {j}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="4" align="right">
                                <button type="button" className="btn btn-sm btn-danger" onClick={this.props.onPrevClick}>Previous</button>
                                &nbsp;<button type="submit" className="btn btn-sm btn-success" onClick={this.onNext.bind(this)}>Next</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export const BranchCard = (props) => {
    return (
        <div className="display-grid-div">
            <div className="display-grid-div-left">
                <h4>{props.branch_name}</h4>
                <h6>{props.estd}, Intake: {props.intake}</h6>
            </div>

            <div className="display-grid-div-right">

                <h6><i onClick={props.onClick} className="fa fa-trash"></i></h6>
            </div>
        </div>
    )
}
