import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class SessionTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    getText(value){
        if(value === 1){
            return "YES";
        }else{
            return "NO";
        }
    }




    render() {
        const columns = [
            {
                name: "Session",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Active",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        
        if(Array.isArray(table_data) && table_data.length > 0){
            
             data = table_data.map((el,index)=>
                [el.session_name,this.getText(el.is_active), <Launch onClick={this.onActionClick.bind(this,el)} />]
            ) 
         
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Session List"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
